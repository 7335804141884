<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19.5V21"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 19.5V21"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 5.78186C3 5.14251 3 4.82283 3.17387 4.43355C3.34773 4.04428 3.52427 3.88606 3.87736 3.56964C5.03437 2.53277 7.36029 1 11 1C14.6397 1 16.9656 2.53277 18.1226 3.56964C18.4757 3.88606 18.6523 4.04428 18.8261 4.43355C19 4.82283 19 5.14251 19 5.78186L19 13C19 15.8284 19 17.2426 18.1213 18.1213C17.2426 19 15.8284 19 13 19L9 19C6.17157 19 4.75736 19 3.87868 18.1213C3 17.2426 3 15.8284 3 13L3 5.78186Z"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M3 13C3 13 6.73333 14 11 14C15.2667 14 19 13 19 13"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.5 16.5H5"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 16.5H18.5"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 16.5L12 16.5"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3 5L19 5" :stroke="iconStroke" stroke-width="1.5" stroke-linejoin="round" />
    <path
      d="M1 8L1 9"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 8L21 9"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '22',
    },
    width: {
      type: String,
      default: '22',
    },
    color: {
      type: String,
      default: 'primary-purple-600',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
